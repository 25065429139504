<template>
    <div>
        <div class="tabs-content-secondblock">
            <div class="row">
                <div class="col-lg-12">
                    <div class="ml-2 mr-1 mb-3">

                        <div class="row">
                            <div class="col-lg-12">
                                <div class="infoHeader px-3 pb-4">
                                    <div
                                        class="create-company-header d-flex align-items-center justify-content-between">
                                        <span class="fs-14 text-white">Basic Information</span>
                                        <!-- <div style="margin-right: 10px;">
                                            <el-tooltip class="item" effect="dark" content="Edit" placement="top">
                                                <button class="btn btn-icon pointer ml-2 mr-2 text-capitalize" @click="enableEdit" style="padding: 2px 6px !important;background: rgb(255, 255, 255);border-radius: 3px !important;">
                                                    <img src="../../assets/Icon material-edit.svg" height="12px;" style="margin-top: -3px;">
                                                    <span class="mx-1 mt-1" style="color: #00448b;font-size: 14px;font-weight: 600;">Edit</span>
                                                </button>
                                            </el-tooltip>
                                        </div> -->
                                        <span class="pointer" @click="expandTabs('basic_info')">
                                            <i class="text-white"
                                                :class="{'icon-chevron-double-up':basic_info_expand, 'icon-chevron-double-down':!basic_info_expand}"
                                                style="font-size: 20px;"></i>
                                        </span>
                                    </div>
                                    <div v-if="basic_info_expand" style="padding: 0 10px;">
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">First
                                                    Name</div>
                                                <div class="w-100 mt-2" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_first_name">
                                                        <div class="pointer" @click="resetValue('first_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('first_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input name='first_name' v-validate="'required'"
                                                            class="input-field-underline"
                                                            @focus="editUser('first_name')"
                                                            maxlength="30"
                                                            :readonly="!edit_enable" 
                                                            v-model="edit_user_data.first_name">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('first_name')">
                                                            This field is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Middle
                                                    Name</div>
                                                <div class="w-100 mt-2" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_middle_name">
                                                        <div class="pointer" @click="resetValue('middle_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('middle_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input class="input-field-underline"
                                                            @focus="editUser('middle_name')"
                                                            maxlength="30"
                                                            :readonly="!edit_enable"
                                                            v-model="edit_user_data.middle_name">
                                                    <!-- </el-tooltip> -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">Last
                                                    Name</div>
                                                <div class="w-100 mt-2" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_last_name">
                                                        <div class="pointer" @click="resetValue('last_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('last_name')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input name='last_name' v-validate="'required'" 
                                                            class="input-field-underline" @focus="editUser('last_name')"
                                                            maxlength="30"
                                                            :readonly="!edit_enable"
                                                            v-model="edit_user_data.last_name">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('last_name')">
                                                            This field is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Gender</div>
                                                <div v-if="edit_enable" class="w-100"
                                                    style="font-weight:600;position: relative;">
                                                    <!-- <div v-if="edit_gender" style="z-index: 2;"
                                                        class="d-flex justify-content-end align-items-center new-action-btns">
                                                        <div class="pointer" @click="resetValue('gender')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('gender')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable"
                                                        class="form-check form-check-inline mt-2 ml-1">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_gender" @change="maleGender"
                                                                v-validate="'required'" v-model="edit_user_data.gender"
                                                                name="vendor_type" value="Male">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Male</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable"
                                                        class="form-check form-check-inline ml-5">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_gender" @change="femaleGender"
                                                                v-model="edit_user_data.gender" v-validate="'required'"
                                                                name="vendor_type" value="Female">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Female</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable" class="form-check form-check-inline"
                                                        :class="{'w-100':custom_gender_active, 'ml-5':!custom_gender_active}">
                                                        <label class="form-check-label"
                                                            :class="{'w-100':custom_gender_active}">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_gender" @change="customGenderChange"
                                                                v-model="edit_user_data.gender" v-validate="'required'"
                                                                name="vendor_type" value="Custom">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Custom<input
                                                                    style="border-bottom:1px solid black"
                                                                    v-if="gender_radio == 'Custom'" type="text"></span>
                                                            <div v-if="custom_gender_active" class="w-100"
                                                                style="position: relative;">
                                                                <input name="gender" :maxlength="maxlengthcustomname"
                                                                    v-validate="'required'" type="text"
                                                                    v-model="edit_user_data.custom_gender"
                                                                    style="margin-top: -3px; margin-left: 10px; padding-right: 60px; border-bottom: solid 0.5px #cdd2da !important; background: transparent; width: 98%;">
                                                            </div>
                                                        </label>
                                                    </div>
                                                </div>
                                                <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="Click to edit"
                                                    placement="top"> -->
                                                    <div v-if="!edit_enable" class="w-100" @click="editUser('gender')">
                                                        <div v-if="edit_user_data.custom_gender.length>0"
                                                            class="input-field-underline">
                                                            {{edit_user_data.custom_gender}}
                                                        </div>
                                                        <div v-else class="input-field-underline">
                                                            {{edit_user_data.gender}}
                                                        </div>
                                                    </div>
                                                <!-- </el-tooltip> -->

                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Date of Birth
                                                </div>
                                                <div class="w-100">

                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_date_of_birth">
                                                        <div class="pointer" @click="resetValue('date_of_birth')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('date_of_birth')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div :class="{'mr-12':edit_date_of_birth}">
                                                        <!-- <el-tooltip :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark" content="Click to edit"
                                                            placement="top"> -->
                                                            <date-picker
                                                                :disabled="!edit_enable"
                                                                class="profile-date-picker calendar-edit-per input-field-underline pt-0 pb-0 pr-0"
                                                                @focus="editUser('date_of_birth')"
                                                                v-model="edit_user_data.date_of_birth" value-type="format"
                                                                :clearable="false" :not-after="disable_start_year" :default-value="disable_start_year"
                                                                :editable="false" style="padding-left: 0px !important"
                                                                lang="en" type="date"
                                                                format="DD/MM/YYYY"></date-picker>
                                                        <!-- </el-tooltip> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">Blood
                                                    Group</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_blood_group">
                                                        <div class="pointer" @click="resetValue('blood_group')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('blood_group')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable" class="mr-12" style="width: 100% !important">
                                                        <multiselect
                                                            class="diginew-multiselect diginew-multiselect-underline"
                                                            name="blood_type" :searchable="true"
                                                            :options="blood_type_options"
                                                            :show-labels="false"
                                                            v-model="edit_user_data.blood_group"
                                                            placeholder="Select Blood Group" style="width: 100% !important">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is
                                                                    Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No
                                                                    Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <div v-if="!edit_enable" @click="editUser('blood_group')" class="input-field-underline">
                                                            {{edit_user_data.blood_group}}
                                                        </div>
                                                    <!-- </el-tooltip> -->

                                                </div>
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span class="validation" v-show="errors.has('blood_group')">
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Personal Email</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_personal_email">
                                                        <div class="pointer" @click="resetValue('personal_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('personal_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input name='personal_email' v-validate="'required|email'"
                                                            class="input-field-underline"
                                                            @focus="editUser('personal_email')"
                                                            style="padding-top: 6px !important;"
                                                            :readonly="!edit_enable" 
                                                            v-model="edit_user_data.personal_email">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('personal_email')">
                                                            This field is required
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Business Email</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_business_email">
                                                        <div class="pointer" @click="resetValue('business_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('business_email')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input :disabled="true" name='business_email' v-validate="'required|email'"
                                                            class="input-field-underline"
                                                            @focus="editUser('business_email')"
                                                            style="padding-top: 6px !important;"
                                                            :readonly="!edit_enable" 
                                                            v-model="edit_user_data.business_email">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('business_email')">
                                                            This field is required
                                                        </span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">Employment Id
                                                    </div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_employment_id">
                                                        <div class="pointer" @click="resetValue('employment_id')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('employment_id')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div>
                                                    <el-tooltip class="item" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" effect="dark" content="Click to edit"
                                                        placement="top"> -->
                                                        <input :disabled="true" name='employment_id' v-validate="'required'"
                                                            class="input-field-underline"
                                                            @focus="editUser('employment_id')"
                                                            style="padding-top: 6px !important;"
                                                            :readonly="!edit_enable" 
                                                            v-model="edit_user_data.employment_id">
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('employment_id')">
                                                            This field is required
                                                        </span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Department</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_department">
                                                        <div class="pointer" @click="resetValue('department')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('department')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable" class="mr-12" style="width: 100% !important;">
                                                        <multiselect
                                                            class="diginew-multiselect diginew-multiselect-underline"
                                                            name="department_type" :searchable="true"
                                                            :options="department_type_options"
                                                            :show-labels="false"
                                                            label="name"
                                                            :loading="department_unit_loading"
                                                            @open="getDepartmentUnitOptions"
                                                            v-model="edit_user_data.department"
                                                            placeholder="Select Department">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is
                                                                    Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No
                                                                    Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <div v-if="!edit_enable" @click="editUser('department')" class="input-field-underline">
                                                            {{edit_user_data.department.name}}
                                                        </div>
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('department')">
                                                            This field is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Branch</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_branch">
                                                        <div class="pointer" @click="resetValue('branch')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('branch')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable" class="mr-12" style="width: 100% !important;">
                                                        <multiselect
                                                            class="diginew-multiselect diginew-multiselect-underline"
                                                            name="branch_type" :searchable="true"
                                                            :options="branch_type_options"
                                                            v-model="edit_user_data.branch"
                                                            :show-labels="false"
                                                            :loading="branch_loading"
                                                            label="name"
                                                            @open="getBranchOptions"
                                                            placeholder="Select Branch">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is
                                                                    Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No
                                                                    Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <div v-if="!edit_enable" @click="editUser('branch')" class="input-field-underline">
                                                            {{edit_user_data.branch.name}}
                                                        </div>
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('branch')">
                                                            This field is required
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Business Units</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_business_units">
                                                        <div class="pointer" @click="resetValue('business_units')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('business_units')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable" class="mr-12" style="width: 100% !important;">
                                                        <multiselect
                                                            class="diginew-multiselect diginew-multiselect-underline"
                                                            name="business_units_type" :searchable="true"
                                                            :options="business_units_type_options"
                                                            :show-labels="false"
                                                            :loading="business_unit_loading"
                                                            label="name"
                                                            @open="getBusinessUnitOptions"
                                                            v-model="edit_user_data.business_units"
                                                            placeholder="Select Business Units">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is
                                                                    Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No
                                                                    Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <div v-if="!edit_enable" @click="editUser('business_units')" class="input-field-underline" style="overflow-wrap:anywhere">
                                                            {{edit_user_data.business_units.name}}
                                                        </div>
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation" v-show="errors.has('business_units')">
                                                            This field is required
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    User Access</div>
                                                <div v-if="edit_enable" class="w-100"
                                                    style="font-weight:600;position: relative;">
                                                    <!-- <div style="z-index: 2;"
                                                        class="d-flex justify-content-end align-items-center new-action-btns">
                                                        <div class="pointer" @click="resetValue('user_access')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer" @click="updateInfo('user_access')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable"
                                                        class="form-check form-check-inline mt-2 ml-1">
                                                        <label v-if="edit_user_data.user_access == 'super_admin'" class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_user_access" @change="superAdminUser"
                                                                v-validate="'required'"
                                                                v-model="edit_user_data.user_access" name="super_admin"
                                                                value="super_admin">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Super
                                                                Admin</span>
                                                        </label>
                                                        <label v-if="edit_user_data.user_access == 'super admin'" class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_user_access" @change="superAdminUser"
                                                                v-validate="'required'"
                                                                v-model="edit_user_data.user_access" name="super_admin"
                                                                value="super admin">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Super
                                                                Admin</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable"
                                                        class="form-check form-check-inline ml-5">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_user_access" @change="adminUser"
                                                                v-model="edit_user_data.user_access"
                                                                v-validate="'required'" name="admin"
                                                                value="admin">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">Admin</span>
                                                        </label>
                                                    </div>
                                                    <div v-if="edit_enable"
                                                        class="form-check form-check-inline ml-5">
                                                        <label class="form-check-label">
                                                            <input class="form-check-input" type="radio"
                                                                :readonly="!edit_user_access" @change="User"
                                                                v-model="edit_user_data.user_access"
                                                                v-validate="'required'" name="employee" value="employee">
                                                            <span class="radio-icon"></span>
                                                            <span class="form-check-description mt-1px">User</span>
                                                        </label>
                                                    </div>

                                                </div>
                                                <!-- <el-tooltip v-else class="item" :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" effect="dark" content="Click to edit"
                                                    placement="top"> -->
                                                    <div v-if="!edit_enable" class="w-100" @click="editUser('user_access')">
                                                        <input name='user_access' v-validate="'required'"
                                                            class="input-field-underline"
                                                            @focus="editUser('user_access')"
                                                            style="padding-top: 6px !important;"
                                                            :readonly="!edit_enable"
                                                            v-model="edit_user_data.user_access">
                                                    </div>
                                                <!-- </el-tooltip> -->
                                                <div class="d-flex justify-content-between align-items-center">
                                                    <span class="validation" v-show="errors.has('user_access')">
                                                        This field is required
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="loggedInUser.user_role != 'owner'" class="mt-6">
                                            <div class="d-flex align-items-center new-contactView ">
                                                <div class="fs-14 fw-600 text-secondary profile-content-left-text">
                                                    Reporting Manager</div>
                                                <div class="w-100" style="position: relative;">
                                                    <!-- <div class="d-flex justify-content-end align-items-center new-action-btns"
                                                        v-if="edit_reporting_manager">
                                                        <div class="pointer" @click="resetValue('reporting_manager')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/cross_new.svg" style="width: 20px;">
                                                        </div>
                                                        <div class="ml-2 pointer"
                                                            @click="updateInfo('reporting_manager')">
                                                            <img src="https://cdn.digicollect.com/cdn/dc_crm_fe/admin_menus/check_new.svg" style="width: 20px;">
                                                        </div>
                                                    </div> -->
                                                    <div v-if="edit_enable" class="mr-12" style="width:100% !important">
                                                        <multiselect
                                                            class="diginew-multiselect diginew-multiselect-underline"
                                                            name="reporting_manager_type" :searchable="true"
                                                            :options="reporting_manager_type_options"
                                                            :show-labels="false"
                                                            :loading="reporting_manager_loading"
                                                            label="full_name"
                                                            @open="getReportingManagerOptions"
                                                            v-model="edit_user_data.reporting_manager"
                                                            placeholder="Select Reporting Manager">
                                                            <template slot="noOptions">
                                                                <div class="text-secondary text-center fw-600">List is
                                                                    Empty</div>
                                                            </template>
                                                            <template slot="noResult">
                                                                <div class="text-secondary text-center fw-600">No
                                                                    Results Found</div>
                                                            </template>
                                                        </multiselect>
                                                    </div>
                                                    <!-- <el-tooltip v-else :disabled="loggedInUser.user_role == 'admin' || loggedInUser.user_role == 'employee' || loggedInUser.user_role == 'consultant'" class="item" effect="dark"
                                                            content="Click to edit"
                                                            placement="top"> -->
                                                        <div v-if="!edit_enable" @click="editUser('reporting_manager')" class="input-field-underline">
                                                            {{edit_user_data.reporting_manager.full_name}}
                                                        </div>
                                                    <!-- </el-tooltip> -->
                                                    <div class="d-flex justify-content-between align-items-center">
                                                        <span class="validation"
                                                            v-show="errors.has('reporting_manager')">
                                                            This field is required
                                                        </span>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-if="edit_enable" class="d-flex align-items-center justify-content-center pt-4 pb-2">
                            <button class="btn btn-outline-secondary" :disabled="save_api_call_in_progress" @click="editDisable" style="width: 120px;border-radius: 5px;">Cancel</button>
                            <button class="btn btn-new-success ml-5" :disabled="save_api_call_in_progress" @click="updateInfo" style="width: 120px;border-radius: 5px;">Save
                                 <hollow-dots-spinner v-if="save_api_call_in_progress" style="position: absolute;width: 160px !important;margin: 0;top: 12px;left: -15px;display: flex;justify-content: center;" :animation-duration="1200" :dot-size="10" :dots-num="3" :color="'#00448b'" />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <sweet-modal ref="warning_modal" overlay-theme="dark" icon="warning">{{warning_msg}}</sweet-modal>
        <sweet-modal ref="success_modal" overlay-theme="dark" icon="success">{{success_msg}}</sweet-modal>
    </div>
</template>
<script>
     import {
        EventBus
    } from "../eventBus/nav-bar-event.js";
    import moment from "moment";
    import { SweetModal } from 'sweet-modal-vue';
    import DatePicker from 'vue2-datepicker'
    import Element from 'element-ui'
    import companies from '../mixins/companies';
    import bloodgroup from '../json/bloodgroup.json';
import axios from 'axios';
import globals from '../globals';

    export default {
        props: ["emp_data", "comp_data"],
        components: {
            DatePicker,
            Element,
            SweetModal
        },
        data() {
            return {
                save_api_call_in_progress: false,
                maxlengthcustomname: 15,
                custom_gender_active: false,
                custom_user_access_active: false,
                about_editing: false,
                basic_editing: false,
                warning_msg: '',
                success_msg: '',
                edit_user_data: {
                    first_name: '',
                    middle_name: '',
                    last_name: '',
                    date_of_birth: '',
                    blood_group: '',
                    personal_email: '',
                    business_email: '',
                    employment_id: '',
                    department: '',
                    branch: '',
                    business_units: '',
                    gender: '',
                    custom_gender: '',
                    user_access: '',
                    reporting_manager: ''
                },
                edit_first_name: false,
                edit_middle_name: false,
                edit_last_name: false,
                edit_gender: '',
                edit_blood_group: false,
                edit_date_of_birth: false,
                edit_personal_email: false,
                edit_business_email: false,
                gender_radio: '',
                edit_job_description: false,
                edit_tax_id: false,
                edit_reporting_manager: false,
                edit_business_units: false,
                edit_department: false,
                edit_user_access: false,
                edit_branch: false,
                edit_employment_id: false,
                edit_business_units: false,
                basic_info_expand: true,
                blood_type_options: bloodgroup,

                reporting_manager_type_options: [],
                business_units_type_options: [],
                branch_type_options: [],
                department_type_options: [],
                ajax_call_in_progress: false,
                branch_loading: false,
                business_unit_loading: false,
                department_unit_loading: false,
                reporting_manager_loading: false,
                skip: 0,
                limit: 10,
                search_key: '',
                edit_enable: false,
                disable_start_year: new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())
            };
        },
        mixins: [companies],
        created() {
            EventBus.$on("enableEdit", this.enableEdit);
        },
        destroyed() {
            EventBus.$off("enableEdit", this.enableEdit);
        },
        methods: {
            enableEdit() {
                this.edit_enable = true
                // if(this.edit_user_data.gender == 'custom' || this.edit_user_data.gender == 'Custom') {
                //     this.edit_user_data.gender = 'Custom'
                //     this.custom_gender_active = true
                // }
            },
            updateInfo() {
                this.$validator.validateAll().then(result => {
                    if (result == true && this.personal_email != '') {
                        this.infoUpdate();
                    } else {
                        if(this.personal_email == '') {
                            this.warning_msg = error.response.data.reason;
                            this.$refs.warning_modal.open();
                        }
                    }
                })
            },
            async infoUpdate() {
                    let params = {
                    };
                    params.first_name = this.edit_user_data.first_name;
                    params.middle_name = this.edit_user_data.middle_name;
                    params.last_name = this.edit_user_data.last_name;
                    this.edit_gender = false;
                    if (this.custom_gender_active) {
                        this.custom_gender_active = false;
                    }
                    if (this.edit_user_data.custom_gender.length > 0) {
                        this.edit_user_data.gender = 'Custom';
                        params.gender = 'custom'
                        params.custom_gender = this.edit_user_data.custom_gender
                    } else {
                        this.edit_user_data.custom_gender = ''
                        params.gender = this.edit_user_data.gender
                    }
                    params.date_of_birth = this.edit_user_data.date_of_birth ? this.edit_user_data.date_of_birth.split("/")[2]+"-"+this.edit_user_data.date_of_birth.split("/")[1]+"-"+this.edit_user_data.date_of_birth.split("/")[0] : ""
                    params.blood_group = this.edit_user_data.blood_group;
                    params.personal_email = this.edit_user_data.personal_email;
                    // params.business_email = this.edit_user_data.business_email;
                    if (this.edit_user_data.employment_id != ''){
                        params.employment_id = this.edit_user_data.employment_id;
                    }
                    params.department_id = this.edit_user_data.department.id;
                    params.branch_id = this.edit_user_data.branch.id;
                    params.business_unit_id = this.edit_user_data.business_units.id;
                    if (this.loggedInUser.user_role == 'super_admin' || this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'admin'){
                        params.user_access = this.edit_user_data.user_access;
                        if(params.user_access == 'owner'){
                            delete params['user_access']
                        }
                    }
                    
                    // if (this.edit_user_data.user_access == 'admin' || 'user'){
                    //     params.user_access = this.edit_user_data.user_access;
                    // }
                    // params.user_access = this.edit_user_data.user_access;
                    params.reporting_manager = this.edit_user_data.reporting_manager.user_id;
                    this.save_api_call_in_progress = true
                    // let response = await this.updatePersonalInfo(params, this.loggedInUser.user_id);
                    await axios.patch(globals.AUTH_SERVICE + `/employees/${this.$route.params.id}`, params).then((response) => {
                        if(response.data.status_id == 1) {
                            this.success_msg = response.data.message
                            this.$refs.success_modal.open();
                            setTimeout(() => {
                                this.$refs.success_modal.close();
                                this.$emit("updateSuccess");
                            }, 2000);
                        }
                    }).catch((error) => {
                        this.warning_msg = error.response.data.reason;
                        this.$refs.warning_modal.open();
                        this.save_api_call_in_progress = false
                    });
            },
            editDisable() {
                let emp_data = JSON.parse(localStorage.getItem('emp_data'));

                this.edit_enable = false
                this.edit_user_data.first_name = emp_data.first_name == undefined || null ? '' : emp_data.first_name;
                
                this.edit_user_data.middle_name = emp_data.middle_name == undefined || null ? '' : emp_data.middle_name;
               
                this.edit_user_data.last_name = emp_data.last_name == undefined || null ? '' : emp_data.last_name;
                
                if (this.custom_gender_active) {
                    this.custom_gender_active = false;
                    this.edit_user_data.custom_gender = ''
                }
                if (this.edit_user_data.custom_gender.length > 0) {
                    this.edit_user_data.gender = 'Custom';
                    this.edit_user_data.custom_gender = emp_data.custom_gender == undefined || null ? '' : emp_data.custom_gender;
                } else {
                    this.edit_user_data.custom_gender = '';
                    this.edit_user_data.gender = emp_data.gender == undefined || null ? '' : emp_data.gender;
                }
                this.edit_user_data.date_of_birth = emp_data.date_of_birth == undefined || null ? '' : emp_data.date_of_birth.split('-')[2]+'/'+emp_data.date_of_birth.split('-')[1]+'/'+emp_data.date_of_birth.split('-')[0];
                
                this.edit_user_data.blood_group = emp_data.blood_group == undefined || null ? '' : emp_data.blood_group;
                
                this.edit_user_data.personal_email = emp_data.personal_email == undefined || null ? '' : emp_data.personal_email;
               
                this.edit_user_data.business_email = emp_data.business_email == undefined || null ? '' : emp_data.business_email;
               
                this.edit_user_data.employment_id = emp_data.employment_id == undefined || null ? '' : emp_data.employment_id;
              
                this.edit_user_data.department.name = emp_data.department == undefined || null ? '' : emp_data.department;
               
                this.edit_user_data.branch.name = emp_data.branch == undefined || null ? '' : emp_data.branch;
                
                this.edit_user_data.business_units.name = emp_data.business_unit == undefined || null ? '' : emp_data.business_unit;
                
                this.edit_user_data.user_access = emp_data.user_access == undefined || null ? '' : emp_data.user_access;
                
                this.edit_user_data.reporting_manager.full_name = emp_data.reporting_manager == undefined || null ? '' : emp_data.reporting_manager;
               
            },
            moment(date) {
                return moment(date);
            },
            expandTabs(type) {

                if (type == 'basic_info') {
                    this.basic_info_expand = !this.basic_info_expand;
                }
            },
            customDeselected() {
                if (this.custom_gender_active) {
                    this.custom_gender_active = false;
                    this.edit_user_data.gender = "Male";
                }
            },

            editUser(obj) {
                if((this.loggedInUser.user_role == 'owner' || this.loggedInUser.user_role == 'super admin' || this.loggedInUser.user_role == 'super_admin') || (this.loggedInUser.user_role == 'admin' && (this.loggedInUser.company_id == this.$route.params.company_id))) {
                    if (obj == 'first_name') {
                        this.edit_first_name = true;
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'middle_name') {
                        this.edit_first_name = false;
                        this.edit_middle_name = true;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'last_name') {
                        this.edit_middle_name = false;
                        this.edit_last_name = true;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'gender') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = true;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'date_of_birth') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = true;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'blood_group') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = true,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'personal_email') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = true,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'business_email') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = true,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'date_of_birth') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = true,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'employment_id') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = true,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'business_units') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = true,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'user_access') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = true,
                        this.edit_reporting_manager = false
                    } else if (obj == 'reporting_manager') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = true
                    } else if (obj == 'branch') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = true,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = false,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    } else if (obj == 'department') {
                        this.edit_middle_name = false;
                        this.edit_last_name = false;
                        this.edit_gender = false;
                        this.edit_date_of_birth = false;
                        this.edit_blood_group = false,
                        this.edit_date_of_birth = false,
                        this.edit_personal_email = false,
                        this.edit_business_email = false,
                        this.edit_branch = false,
                        this.edit_employment_id = false,
                        this.edit_business_units = false,
                        this.edit_department = true,
                        this.edit_user_access = false,
                        this.edit_reporting_manager = false
                    }
                }
            },
            customGenderChange(data) {
                if (data.target.value == 'Custom') {
                    this.custom_gender_active = true;
                }
            },
            femaleGender(data) {
                if (data.target.value == 'Female') {
                    this.edit_user_data.gender = 'Female';
                    this.edit_user_data.custom_gender = '';
                }
            },
            maleGender(data) {
                if (data.target.value == 'Male') {
                    this.edit_user_data.gender = 'Male';
                    this.edit_user_data.custom_gender = '';
                }
            },
            superAdminUser(data) {
                if (data.target.value == 'SuperAdmin') {
                    this.edit_user_data.user_access = 'super_admin';
                    //this.edit_user_data.custom_user_access = '';
                }
            },
            adminUser(data) {
                if (data.target.value == 'Admin') {
                    this.edit_user_data.user_access = 'admin';
                    //this.edit_user_data.custom_user_access = '';
                }
            },
            User(data) {
                if (data.target.value == 'User') {
                    this.edit_user_data.user_access = 'user';
                    //this.edit_user_data.custom_user_access = '';
                }
            },
            // resetValue(obj, index) {
            //     let emp_data = JSON.parse(localStorage.getItem('emp_data'));
            //     if (obj == 'first_name') {
            //         this.edit_first_name = false;
            //         this.edit_user_data.first_name = emp_data.first_name == undefined || null ? '' : emp_data.first_name;
            //     } else if (obj == 'middle_name') {
            //         this.edit_middle_name = false;
            //         this.edit_user_data.middle_name = emp_data.middle_name == undefined || null ? '' : emp_data.middle_name;
            //     } else if (obj == 'last_name') {
            //         this.edit_last_name = false;
            //         this.edit_user_data.last_name = emp_data.last_name == undefined || null ? '' : emp_data.last_name;
            //     } else if (obj == 'gender') {
            //         this.edit_gender = false;
            //         if (this.custom_gender_active) {
            //             this.custom_gender_active = false;
            //             this.edit_user_data.custom_gender = ''
            //         }
            //         if (this.edit_user_data.custom_gender.length > 0) {
            //             this.edit_user_data.gender = 'Custom';
            //             this.edit_user_data.custom_gender = emp_data.custom_gender == undefined || null ? '' : emp_data.custom_gender;
            //         } else {
            //             this.edit_user_data.custom_gender = '';
            //             this.edit_user_data.gender = emp_data.gender == undefined || null ? '' : emp_data.gender;
            //         }
            //     } else if (obj == 'date_of_birth') {
            //         this.edit_date_of_birth = false;
            //         this.edit_user_data.date_of_birth = emp_data.date_of_birth == undefined || null ? '' : emp_data.date_of_birth.split('-')[2]+'/'+emp_data.date_of_birth.split('-')[1]+'/'+emp_data.date_of_birth.split('-')[0];
            //     } else if (obj == 'blood_group') {
            //         this.edit_blood_group = false;
            //         this.edit_user_data.blood_group = emp_data.blood_group == undefined || null ? '' : emp_data.blood_group;
            //     } else if (obj == 'personal_email') {
            //         this.edit_personal_email = false;
            //         this.edit_user_data.personal_email = emp_data.personal_email == undefined || null ? '' : emp_data.personal_email;
            //     } else if (obj == 'business_email') {
            //         this.edit_business_email = false;
            //         this.edit_user_data.business_email = emp_data.business_email == undefined || null ? '' : emp_data.business_email;
            //     } else if (obj == 'employment_id') {
            //         this.edit_employment_id = false;
            //         this.edit_user_data.employment_id = emp_data.employment_id == undefined || null ? '' : emp_data.employment_id;
            //     } else if (obj == 'department') {
            //         this.edit_department = false;
            //         this.edit_user_data.department.name = emp_data.department == undefined || null ? '' : emp_data.department;
            //     } else if (obj == 'branch') {
            //         this.edit_branch = false;
            //         this.edit_user_data.branch.name = emp_data.branch == undefined || null ? '' : emp_data.branch;
            //     } else if (obj == 'business_units') {
            //         this.edit_business_units = false;
            //         this.edit_user_data.business_units.name = emp_data.business_unit == undefined || null ? '' : emp_data.business_unit;
            //     } else if (obj == 'user_access') {
            //         this.edit_user_access = false;
            //         this.edit_user_data.user_access = emp_data.user_access == undefined || null ? '' : emp_data.user_access;
            //     } else if (obj == 'reporting_manager') {
            //         this.edit_reporting_manager = false;
            //         this.edit_user_data.reporting_manager.full_name = emp_data.reporting_manager == undefined || null ? '' : emp_data.reporting_manager;
            //     }
            // },
            async getBranchOptions() {
                this.branch_type_options = [];
                this.branch_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListBranch(params.skip, params.limit, params.company_id);
                    if(response.status_id == 1) {
                        let branch_data = response.response;
                        branch_data.forEach((branch, index) => {
                            this.branch_type_options.push({
                                id: branch.id,
                                name: branch.name
                            })
                        });
                    }
                    this.branch_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getBusinessUnitOptions() {
                this.business_units_type_options = [];
                this.business_unit_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListBusinessUnit(params);
                    if(response.status_id == 1) {
                        let bus_data = response.response;
                        bus_data.forEach((business_unit, index) => {
                            this.business_units_type_options.push({
                                id: business_unit.id,
                                name: business_unit.name
                            })
                        });
                    }
                    this.business_unit_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getDepartmentUnitOptions() {
                this.department_type_options = [];
                this.department_unit_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListDepartmentUnit(params);
                    if(response.status_id == 1) {
                        let department_data = response.response;
                        department_data.forEach((department_unit, index) => {
                            this.department_type_options.push({
                                id: department_unit.id,
                                name: department_unit.name
                            })
                        });
                    }
                    this.department_unit_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            async getReportingManagerOptions() {
                this.reporting_manager_type_options = [];
                this.reporting_manager_loading = true;
                try {
                    let params = {
                        skip: this.skip,
                        limit: this.limit,
                        search_key: this.search_key,
                        company_id: this.$route.params.company_id
                    }
                    let response = await this.getListEmployees(params);
                    if(response.status_id == 1) {
                        let reporting_manager_data = response.response;
                        reporting_manager_data.forEach((reporting_manager, index) => {
                            this.reporting_manager_type_options.push({
                                user_id: reporting_manager.user_id,
                                full_name: reporting_manager.full_name
                            })
                        });
                    }
                    this.reporting_manager_loading = false;
                }
                catch(err) {
                    // console.log(err);
                }
            },
            // async updateInfo(obj, index) {
            //     let params = {
            //     };
            //     if (obj == 'first_name') {
            //         params.first_name = this.edit_user_data.first_name;
            //     } else if (obj == 'middle_name') {
            //         params.middle_name = this.edit_user_data.middle_name;
            //     } else if (obj == 'last_name') {
            //         params.last_name = this.edit_user_data.last_name;
            //     } else if (obj == 'gender') {
            //         this.edit_gender = false;
            //         if (this.custom_gender_active) {
            //             this.custom_gender_active = false;
            //         }
            //         if (this.edit_user_data.custom_gender.length > 0) {
            //             this.edit_user_data.gender = 'Custom';
            //             params.gender = 'custom'
            //             params.custom_gender = this.edit_user_data.custom_gender
            //         } else {
            //             this.edit_user_data.custom_gender = ''
            //             params.gender = this.edit_user_data.gender
            //         }
            //     } else if (obj == 'date_of_birth') {
            //         params.date_of_birth = this.edit_user_data.date_of_birth ? this.edit_user_data.date_of_birth.split("/")[2]+"-"+this.edit_user_data.date_of_birth.split("/")[1]+"-"+this.edit_user_data.date_of_birth.split("/")[0] : ""
            //     } else if (obj == 'blood_group') {
            //         params.blood_group = this.edit_user_data.blood_group;
            //     } else if (obj == 'personal_email') {
            //         params.personal_email = this.edit_user_data.personal_email;
            //     } else if (obj == 'business_email') {
            //         params.business_email = this.edit_user_data.business_email;
            //     } else if (obj == 'employment_id') {
            //         params.employment_id = this.edit_user_data.employment_id;
            //     } else if (obj == 'department') {
            //         params.department_id = this.edit_user_data.department.id;
            //     } else if (obj == 'branch') {
            //         params.branch_id = this.edit_user_data.branch.id;
            //     } else if (obj == 'business_units') {
            //         params.business_unit_id = this.edit_user_data.business_unit.id;
            //     } else if (obj == 'user_access') {
            //         params.user_access = this.edit_user_data.user_access;
            //     } else if (obj == 'reporting_manager') {
            //         params.reporting_manager = this.edit_user_data.reporting_manager.user_id;
            //     }
            //     await axios.patch(globals.AUTH_SERVICE + `/employees/${this.$route.params.id}`, params).then((response) => {
            //         if(response.data.status_id == 1) {
            //             this.success_msg = response.data.message
            //             this.$refs.success_modal.open();
            //             setTimeout(() => {
            //                 this.$refs.success_modal.close();
            //                 this.$emit("updateSuccess");
            //             }, 2000);
            //         }
            //     }).catch((error) => {
            //         this.warning_msg = error.response.data.reason;
            //         this.$refs.warning_modal.open();
            //     });
            // }
        },
        mounted() {
            localStorage.setItem('emp_data', JSON.stringify(this.emp_data));
            this.edit_user_data.first_name = this.emp_data.first_name;
            this.edit_user_data.middle_name = this.emp_data.middle_name;
            this.edit_user_data.last_name = this.emp_data.last_name;
            this.edit_user_data.date_of_birth = this.emp_data.date_of_birth ? this.emp_data.date_of_birth.split('-')[2]+'/'+this.emp_data.date_of_birth.split('-')[1]+'/'+this.emp_data.date_of_birth.split('-')[0] : '';
            this.edit_user_data.blood_group = this.emp_data.blood_group;
            this.edit_user_data.personal_email = this.emp_data.personal_email;
            this.edit_user_data.business_email = this.emp_data.business_email;
            this.edit_user_data.employment_id = this.emp_data.employment_id;
            this.edit_user_data.department = {
                id: this.emp_data.department_id,
                name: this.emp_data.department
            };
            this.edit_user_data.branch = {
                id: this.emp_data.branch_id,
                name: this.emp_data.branch
            };
            this.edit_user_data.business_units = {
                id: this.emp_data.business_unit_id,
                name: this.emp_data.business_unit,
            };
            this.edit_user_data.gender = this.emp_data.gender;
            this.edit_user_data.custom_gender = this.emp_data.custom_gender;
            this.edit_user_data.user_access = this.emp_data.user_access;
            this.edit_user_data.reporting_manager = {
                user_id: this.emp_data.user_id,
                full_name: this.emp_data.reporting_manager
            };
        },
        watch: {},
        computed: {
            loggedInUser() {
                return this.$store.getters.loggedInUser;
            },
        },
        beforeDestroy() {
            localStorage.removeItem('emp_data');
        },
    };
</script>
<style scoped>
    .new-contactView-item {
        padding: 16px 10px 16px 3px !important;
    }

    .new-contactView {
        display: flex;
        align-items: center;
    }

    .validation {
        color: red;
    }

    .new-contact-value {
        margin-left: 0px !important;
    }

    .create-company-header {
        padding: 9.9px 15px;
        background-color: #00448b;
        margin-left: -20px;
        margin-right: -15px;
    }

    .mx-datepicker {
        width: 100% !important;
    }

    .mx-input-append {
        right: 4px !important;
    }
</style>